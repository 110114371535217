import externalLinks from "./externalLinks";
import { useRef, useEffect } from "react";
import fadeInTransition from "./fadeInTransition";
import bounceAnimation from "./bounceAnimation";

export default function SectionText() {
  let helloRef = useRef(null);
  // let parentheses = useRef(null);
  let commaRef = useRef(null);
  let periodRef = useRef(null);
  let nameRef = useRef(null);
  let sweAtFbRef = useRef(null);
  let hereYouCanFindRef = useRef(null);
  let githubRef = useRef(null);
  let linkedInRef = useRef(null);
  let resumeRef = useRef(null);

  const helloStartTime = 0;
  // const parenthesesStartTime = 0.75;

  const restStartTime = 2;
  const delaySpeed = 0.75;

  useEffect(() => {
    fadeInTransition(helloRef, helloStartTime);
    bounceAnimation(commaRef, helloStartTime + 1 * delaySpeed);
    fadeInTransition(nameRef, restStartTime);
    bounceAnimation(periodRef, restStartTime + 1 * delaySpeed);
    fadeInTransition(sweAtFbRef, restStartTime + 2 * delaySpeed);
    fadeInTransition(hereYouCanFindRef, restStartTime + 3 * delaySpeed);
    fadeInTransition(githubRef, restStartTime + 4 * delaySpeed);
    fadeInTransition(linkedInRef, restStartTime + 5 * delaySpeed);
    fadeInTransition(resumeRef, restStartTime + 6 * delaySpeed);
  }, []);

  const hello = (
    <div>
      <div
        ref={(el) => {
          helloRef = el;
        }}
        className="HelloText"
      >
        hello
      </div>
      <div
        className="CommaText"
        ref={(el) => {
          commaRef = el;
        }}
      >
        ,
      </div>
    </div>
  );

  const myNameIsTorrance = (
    <div
      ref={(el) => {
        nameRef = el;
      }}
    >
      my name is<br></br>
      <a href={externalLinks.torranceMap} className="Name">
        torrance
      </a>
      <div
        className="PeriodText"
        ref={(el) => {
          periodRef = el;
        }}
      >
        .
      </div>
    </div>
  );
  const sweAtFB = (
    <div
      ref={(el) => {
        sweAtFbRef = el;
      }}
    >
      i am a software engineer at <br></br>
      <a href={externalLinks.meta} className="Facebook">
        facebook
      </a>
      .
    </div>
  );

  const hereYouCanFindMy = (
    <div
      ref={(el) => {
        hereYouCanFindRef = el;
      }}
    >
      here you can<br></br>find my<br></br>
    </div>
  );

  function genLink(href, className, text, lastLink = false) {
    return (
      <div>
        {lastLink ? "& " : ""}
        <a href={href} className={className}>
          {text}
        </a>
        {lastLink ? "." : ""}
        <br></br>
      </div>
    );
  }

  return (
    <div className="BodyText">
      <div className="BodyTextPhrase">
        {hello}
        {myNameIsTorrance}
      </div>
      <div className="BodyTextPhrase">{sweAtFB}</div>
      <div className="BodyTextPhrase">
        {hereYouCanFindMy}
        <div
          ref={(el) => {
            githubRef = el;
          }}
        >
          {genLink(externalLinks.github, "Github", "github")}
        </div>
        <div
          ref={(el) => {
            linkedInRef = el;
          }}
        >
          {genLink(externalLinks.linkedIn, "LinkedIn", "linkedin")}
        </div>
        <div
          ref={(el) => {
            resumeRef = el;
          }}
        >
          {genLink(externalLinks.resume, "Resume", "resume", true)}
        </div>
      </div>
    </div>
  );
}
