import "./App.css";
import { useEffect, useRef } from "react";
import fadeInTransition from "./components/fadeInTransition";
import Landing from "./components/Landing";
// eslint-disable-next-line
import AnimatedCursor from "react-animated-cursor";

export default function App() {
  let landing = useRef(null);

  useEffect(() => {
    fadeInTransition(landing, 0.5);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="App">
      <AnimatedCursor outerScale={4} outerSize={8}></AnimatedCursor>
      <div
        ref={(el) => {
          landing = el;
        }}
      >
        <Landing></Landing>
      </div>
    </div>
  );
}
