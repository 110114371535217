import { useEffect, useRef } from "react";
import fadeInTransition from "./fadeInTransition";
import BodyText from "./BodyText";

export default function Section() {
  let section = useRef(null);

  useEffect(() => {
    fadeInTransition(section, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="Body"
      ref={(el) => {
        section = el;
      }}
    >
      <BodyText></BodyText>
    </div>
  );
}
