const externalLinks = {
  torranceMap: "https://www.torranceca.gov/",
  meta: "https://www.facebook.com/creators/professional-mode-for-profiles",
  linkedIn: "https://www.linkedin.com/in/torrance-c/",
  github: "https://github.com/torrancecui",
  resume:
    "https://drive.google.com/file/d/16eRHMinvF0lqpqSJYjHO5MjRacmG23sb/view?usp=sharing",
};

export default externalLinks;
