import { gsap, Power3 } from "gsap";

export default function fadeInTransition(ref, delayTime) {
  gsap.from(ref, 1.5, {
    opacity: 0,
    ease: Power3.easeInOut,
    delay: delayTime,
    autoAlpha: 0,
  });
}
