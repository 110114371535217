// import { useState, useRef, useLayoutEffect } from "react";
// import { gsap, Power3 } from "gsap";
import Body from "./Body";

export default function Landing() {
  // const landing = useRef();
  // const smile = useRef(null);
  // const [active, setActive] = useState(true);
  // const [ctx] = useState(gsap.context(() => {}, landing));

  // useLayoutEffect(() => {
  //   ctx.add("remove", () => {
  //     gsap.to(ctx.selector(".Smile"), {
  //       opacity: 0,
  //       duration: 1,
  //       ease: Power3.easeInOut,
  //       y: -300,
  //       onComplete: () => setActive(false),
  //     });
  //   });
  //   return () => ctx.revert();
  // }, [ctx]);

  // return (
  //   <div ref={landing}>
  //     {active ? (
  //       <button className="SmileButton">
  //         <div
  //           className="Smile"
  //           ref={smile}
  //           onClick={() => active && ctx.remove()}
  //         >
  //           (;
  //         </div>
  //       </button>
  //     ) : (
  //       <Body></Body>
  //     )}
  //   </div>
  // );
  return <Body></Body>;
}
