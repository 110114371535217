import { gsap } from "gsap";

export default function bounceAnimation(ref, delayTime) {
  gsap.from(ref, 1.5, {
    opacity: 0,
    rotateX: 180,
    transformOrigin: "center",
    y: -25,
    ease: "bounce.out",
    delay: delayTime,
  });
}
